@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
body{
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  background-color: #eee;

}

*{
  box-sizing: border-box;
}

.text-field-group {
  display: flex;
  justify-content: space-between;
}

div .result{
  display: block;
  background-color: #fff;
  box-shadow: 1px 1px 3px 2px #ddd;
  margin: 40px auto;
  max-width: 980px;
  padding: 20px 40px;
  width: 100%;
}

div .result input{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
  padding: 7px;
  outline: none;
  font-family: "Poppins";
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: small;
}

form{
  display: block;
  background-color: #fff;
  box-shadow: 1px 1px 3px 2px #ddd;
  margin: 40px auto;
  max-width: 980px;
  padding: 20px 40px;
}

form input, textarea, select{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
  padding: 9px;
  outline: none;
  font-family: "Poppins";
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: small;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  border-bottom: none;
}

.button, .link-button {
  margin: 0;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  border: none;
  outline: none;
  color: #666;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.scroll-to-top-button:hover {
  border: none;
  outline: none;
  color: #444;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container button,span {
  margin-left: 8px;
}

.pagination-link {
  cursor: pointer;
}
